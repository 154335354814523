import React, { useState, useEffect } from 'react'

import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'
import checkConditionalRender from './conditionalRender'

const Number = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label, step = 1, readOnly = false, conditionalRender, conditionalRenderMessage },
  validations,
  languages,
  parentIsSorting,
  innerForm,
  readMode,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState('')

  const onChangeText = e => {
    let newValue = translatable ? value[language] : value
    let decimals = false
    if (step < 1) {
      decimals = step.toString().split('.')[1].length
    }
    if (translatable) {
      newValue[language] = e.target.value
      if (decimals) {
        newValue[language] = parseFloat(newValue[language]).toFixed(decimals)
      }
      setValue({ ...newValue })
    } else {
      newValue = e.target.value
      if (decimals) {
        newValue = parseFloat(newValue).toFixed(decimals)
      }
      // Horrible hack only for eventlocationzone.capacity and inTicketPayment "inTicketPayment" because this component is saving numbers as strings and we need numbers here:
      if ((storedData?.modelName === 'EventLocationZone' && name === 'capacity') || (storedData?.modelName === 'TicketTermsCheckbox' && name === "priceToPay")) {
        newValue = parseInt(e.target.value)
      }
      setValue(newValue)
    }

    setCurrentValue(e.target.value)
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [language, translatable])

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  let color = 'bg-grayLight'
  if (innerForm) {
    color = 'bg-white'
  }
  if (readMode || readOnly) {
    color = 'bg-grayLighter'
  }

  const input = (
    <input
      name={name}
      className={`w-full rounded px-2 py-1 ${
        showErrors && errors.length > 0 ? 'border border-error' : ''
      } ${
        translatable && Object.keys(languages)[0] === language
          ? 'rounded-tl-none'
          : ''
      } ${color}
      outline-none placeholder-gray`}
      type="number"
      placeholder={label}
      value={currentValue}
      onChange={onChangeText}
      disabled={readMode || readOnly}
      step={step}
    />
  )

  if (conditionalRender) {
    const resultCheck = checkConditionalRender({ conditionalRender, conditionalRenderMessage, storedData, label })
    if (resultCheck) {
      return resultCheck
    }
  }

  if (parentIsSorting && name === 'position') {
    return null
  }

  return (
    <div>
      <label>{label}{readOnly && ' (read only)'}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {input}
        </LanguageSelector>
      ) : (
        input
      )}
    </div>
  )
}

export default Number
