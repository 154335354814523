import React, { useEffect } from 'react'
import dayjs from 'dayjs'

const Cell = ({ filterKey, info, visibleColumns, setShowQRTickedId, handleCopyText, events = {} }) => {
  if (!visibleColumns.includes(filterKey)) return false

  if (filterKey === 'event') {
    return <th className="px-2 py-1">
      <span
        title="Copy Ticket ID"
        className="inline-block px-2 py-1 cursor-pointer active:text-primary"
      >
        {events[info.event] || ''}
      </span>
    </th>
  }

  if (filterKey === 'ticketId') {
    return <th className="px-2 py-1">
      <span
        title="Copy Ticket ID"
        className="inline-block px-2 py-1 cursor-pointer active:text-primary"
        onClick={() => setShowQRTickedId(info.ticketId)}
      >
        {info.ticketId}
      </span>
    </th>
  }

  if (filterKey === 'deviceId') {
    return <th
      title="Copy Device ID"
      className="inline-block px-2 py-1 truncate cursor-pointer active:text-primary w-28"
      onClick={() => handleCopyText('Device ID', info.deviceId)}
    >
      {info.deviceId}
    </th>
  }

  if (filterKey === 'age') {
    let minor = ''
    if (dayjs(info.birthDate).isValid() && info.birthDate > dayjs().subtract(18, 'year').utcOffset(1, true).format()) {
      minor = 'Minor'
    } else if (dayjs(info.birthDate).isValid() && info.birthDate < dayjs().subtract(18, 'year').utcOffset(1, true).format()) {
      minor = 'Adult'
    }
    return <th className="px-2 py-1">{minor}</th>
  }

  if (filterKey === 'ticketType') {
    return <th className="px-2 py-1">{info.ticketType && info.ticketType.title}</th>
  }

  if (filterKey === 'name') {
    return <th className="px-2 py-1">{info.name} {info.lastName}</th>
  }

  if (filterKey === 'guestsInvitationName') {
    return <th className="px-2 py-1">{info.guestsInvitationName} {info.guestsInvitationLastName}</th>
  }

  if (filterKey === 'phone') {
    return <th className="px-2 py-1">{info.phoneNumberPrefix} {info.phoneNumber}</th>
  }

  if (filterKey === 'holder') {
    return <th className="px-2 py-1">
      {info.holder?.firstName} {info.holder?.lastName} {info.holder && Object.keys(info.holder).length > 1 && ' - '} {info.holder?.email}
    </th>
  }

  if (filterKey === 'info') {
    return <th className="px-2 py-1">
      {info.info?.firstName} {info.info?.lastName} {(info.info?.firstName || info.info?.lastName) && info.info?.email && ' - '} {info.info?.email}
    </th>
  }

  if (filterKey === 'status') {
    return <th className="px-2 py-1">
      {info.isValid.length > 0 && <span className='text-red'>{info.isValid.join(', ')}</span>}
      {info.isValid.length === 0 && 'VALIDATED'}
    </th>
  }

  if (filterKey === 'exchangeDate') {
    return <th className="px-2 py-1">{info.exchangeDate && dayjs(info.exchangeDate).isValid() ? dayjs(info.exchangeDate).format('DD/MM/YYYY HH:MM:ss') : ''}</th>
  }

  // if (filterKey === 'email') {
  //   return <th className="px-2 py-1">{info.email || info.purchaseEmail || info.guestsInvitationEmail}</th>
  // }

  return <th className="px-2 py-1">{info[filterKey]}</th>
}


export default Cell